import React from "react"

import Layout from "../components/layout"

import ThanYou from "../components/newHome/thankYou"
import ServiceDesc from "../components/services/serviceDesc"


import "../styles/global.css"

const Services = () => {
  return (
    <Layout>
      <ServiceDesc />
      <ThanYou />
    </Layout>
  )
}

export default Services
