import React from "react"

import Container from "../../reusable/absoluteContainer"

import * as styles from "./services.module.css"

import TapestryImg from "../../../assets/images/About_Tapestry_v2.jpg"


const Services = () => {
  return (
    <div className={styles.services}>
      <div className={styles.divWrapper}>
        <div className={styles.servicesContents}>
          <div className={styles.leftContent}>
            <div className={styles.heading}>
              <h1 className={styles.servicesTile}>Visual facilitation</h1>
              <h2 className={styles.servicesSubtitle}>energizes meetings</h2>
            </div>
            <img
              src={TapestryImg}
              alt="About Tapestry"
              className={styles.servicesImage}
            />

            <p className={styles.mobileHeading}>
              <span className={styles.oneClickTile}>Visual facilitation </span>
              energizes meetings
            </p>
            {/* <ul className={styles.servicesLists}>
                {servicesData.map((item, index) => (
                  <li key={index} className={styles.list}>
                    {item}
                  </li>
                ))}
              </ul> */}

            <p className={styles.servicesPara}>
              Level Up Your Meeting Experience with Tapestry, our
              technology-augmented service:
            </p>
            <ul className={styles.servicesLists}>
              <li className={styles.servicesList}>
                <strong>Produces</strong> an immediate visual with key quotes
                and insights during your meeting
              </li>
              <li className={styles.servicesList}>
                <strong>Energizes</strong> your group and deepens conversations
              </li>
              <li className={styles.servicesList}>
                <strong>Reveals</strong> nuances about the group’s attitude and
                work style
              </li>
              <li className={styles.servicesList}>
                <strong>Delivers</strong> a concrete takeaway that will help
                them remember meetings
              </li>
            </ul>
          </div>

          <div className={styles.rightContent}>
            <div className={styles.heading}>
              <h1 className={styles.servicesTile}>Inspire</h1>
              <h2 className={styles.servicesSubtitle}>
                creativity and engagement
              </h2>
            </div>
            <p className={styles.mobileHeading}>
              <span className={styles.servicesTile}>Inspre </span> creativity
              and engagement
            </p>

            {/* <ul className={styles.servicesLists}>
                {servicesData.map((item, index) => (
                  <li key={index} className={styles.servicesList}>
                    {item}
                  </li>
                ))}
              </ul> */}

            <p className={styles.servicesPara}>
              Tapestry provides the following services:
            </p>
            <ul className={styles.servicesLists}>
              <li className={styles.servicesList}>
                <em>Making meetings matter</em>: We work with leaders to set up
                and facilitate a meeting incorporating Tapestry as an accelerant
                for engagement
              </li>
              <li className={styles.servicesList}>
                <em>Strategic Planning</em>: To achieve buy-in from different
                groups, we leverage Tapestry to reflect their input and create
                alignment
              </li>
              <li className={styles.servicesList}>
                <em>Global business transformation</em>: When a company needs to
                transform a function across geographies, Tapestry helps connect
                people and ideas through the international language of visuals
              </li>
              <li className={styles.servicesList}>
                <em>Live Art for Conferences</em>: We bring both the traditional
                meeting visual technique of creating murals on the walls and the
                innovative Tapestry technology to entertain, illuminate and
                document what happens when people gather for a face to face
                conference.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Services
