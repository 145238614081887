import React from "react"

import * as styles from "./button.module.css"
import { Link } from "gatsby"

const Button = ({ text, background, link }) => {
  return (
    <Link href={link ? link : "#"}>
      <div
        className={`${
          background === "gradient"
            ? styles.gradientBtn
            : background === ""
            ? styles.buttonContainer
            : styles.colorButton
        }`}
        style={{ background: `${background ? background : ""}` }}
      >
        <div
          className={`${
            background === "gradient" ? styles.gradientBtn2 : styles.button
          }`}
          style={{ background: `${background ? background : ""}` }}
        >
          {text}
        </div>
      </div>
    </Link>
  )
}

export default Button
