import React from "react"

import Container from "../../reusable/absoluteContainer"
import Footer from "../../layout/newFooter"

import * as styles from "./thankYou.module.css"
import Button from "../../reusable/button"

const ThankYou = () => {
  return (
    <div className={styles.thankYou}>
      <Container>
        <div className={styles.thankYouWrapper}>
          <div className={styles.thankYouBtnContainer}>
            {/* <Button text="Sign up" background="#84B93D" /> */}
            <Button
              text="Contact us"
              background="#019599"
              link="mailto:support@vtapestry.com"
            />
            {/* <Button text="Join our newsletter" background="#455C70" /> */}
          </div>
          <div className={styles.footerSection}>
            <Footer />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default ThankYou
