import React from "react"

import * as styles from "./absoluteContainer.module.css"

const AbsoluteContainer = ({ children, center }) => {
  return (
    <div className={styles.container + " " + `${center && styles.flexCenter}`}>
      {children}
    </div>
  )
}

export default AbsoluteContainer
